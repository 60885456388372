<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
               destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="cangKuBH" label="仓库编号" />
            <x-detail-item prop="chuangJianRQ" label="创建日期" />
            <x-detail-item prop="suoShuMenDian" label="所属门店" />
            <x-detail-item prop="cangKuMC" label="仓库名称" />
            <x-detail-item prop="cangKuMJ" label="仓库面积㎡" width="120px"/>
            <!--            <x-detail-item prop="suoZaiDiQu" label="所在地区" />-->
            <x-detail-item prop="xiangXiDZ" label="详细地址" />
            <x-detail-item prop="fuZeRen" label="负责人" />
            <x-detail-item prop="fuZeRenShouJiHao" label="负责人手机号"  />
            <x-detail-item prop="beiZhu" label="备注" />
            <x-detail-item label="是否默认仓库">
                <x-dict-show :code="entity.shiFuMoRenCangKu" dictType="T_YES_OR_NO"/>
            </x-detail-item>
            <x-detail-item label="启用/禁用">
                <x-dict-show :code="entity.use" dictType="T_USE"/>
            </x-detail-item>
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/kcgl/CangKu";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                cangKuBH: "", // 仓库编号
                chuangJianRQ: "", // 创建日期
                suoShuMenDian: "", // 所属门店
                cangKuMC: "", // 仓库名称
                cangKuMJ: "", // 仓库面积
                suoZaiDiQu: "", // 所在地区
                xiangXiDZ: "", // 详细地址
                fuZeRen: "", // 负责人
                fuZeRenID: "", // 负责人ID
                fuZeRenShouJiHao: "", // 负责人手机号
                beiZhu: "", // 备注
                shiFuMoRenCangKu: "", // 是否默认仓库
                use: "", // 启用/禁用
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>