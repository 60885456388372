<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <!--            <el-form-item label="仓库编号" prop="cangKuBH" >
                            <el-input v-model="form.cangKuBH" size="small" maxlength="36" show-word-limit/>
                        </el-form-item>-->
            <el-form-item label="创建日期" prop="chuangJianRQ">
                <el-date-picker v-model="form.chuangJianRQ" size="small" type="date" value-format="yyyy-MM-dd"/>
            </el-form-item>
            <!--            <el-form-item label="所属门店" prop="suoShuMenDian">-->
            <!--&lt;!&ndash;                <el-input v-model="form.suoShuMenDian" size="small" maxlength="36" show-word-limit/>&ndash;&gt;-->
            <!--                <el-select v-model="form.suoShuMenDian" size="small" maxlength="36" show-word-limit>-->
            <!--                    <el-option v-for="item in menDianOptions" :key="item.suoShuMenDian" :label="item.suoShuMenDian"-->
            <!--                               :value="item.menDianMC"></el-option>-->
            <!--                </el-select>-->
            <!--            </el-form-item>-->
            <el-form-item label="仓库名称" prop="cangKuMC">
                <el-input v-if="form.id!=null" v-model="form.cangKuMC" size="small" maxlength="36" disabled
                          show-word-limit/>
                <el-input v-else v-model="form.cangKuMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="仓库面积" prop="cangKuMJ">
                <el-input v-model="form.cangKuMJ" size="small" maxlength="8" show-word-limit/>
                <span class="unit">㎡</span>
            </el-form-item>
            <!--            <el-form-item label="所在地区" prop="suoZaiDiQu" >
                            <city-picker v-model="form.suoZaiDiQu" :name.sync="form.suoZaiDiQuMC" size="small" style="width:100%"></city-picker>
                        </el-form-item>
            <el-form-item label="所在地区" prop="suoZaiDiQu">
                <city-picker v-model="form.suoZaiDiQu" size="small" style="width:100%"></city-picker>
            </el-form-item>-->
            <el-form-item label="详细地址" prop="xiangXiDZ">
                <el-input v-model="form.xiangXiDZ" size="small" maxlength="64" show-word-limit/>
            </el-form-item>
            <el-form-item label="负责人" prop="fuZeRen">
                <el-input v-model="form.fuZeRen" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <!--            <el-form-item label="负责人ID" prop="fuZeRenID" >
                            <el-input v-model="form.fuZeRenID" size="small" maxlength="36" show-word-limit/>
                        </el-form-item>-->
            <el-form-item label="负责人手机号" prop="fuZeRenShouJiHao" >
                <el-input v-model="form.fuZeRenShouJiHao" size="small" maxlength="11" show-word-limit/>
            </el-form-item>
            <el-form-item label="备注" prop="beiZhu">
                <el-input v-model="form.beiZhu" size="small" maxlength="64" show-word-limit/>
            </el-form-item>
            <el-form-item label="是否默认仓库" prop="shiFuMoRenCangKu">
                <x-selector-one v-model="form.shiFuMoRenCangKu" dictType="T_YES_OR_NO"/>
            </el-form-item>
            <el-form-item label="启用/禁用" @click="refreshMsg" prop="use">
                <x-switch v-model="form.use" dictType="T_USE"/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, use, getUpdate} from "@/service/kcgl/CangKu";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import CityPicker from '@/components/CityPicker/index'
    import {Province,ChineseDistricts} from '@/components/CityPicker/lib/city-picker.data'
    import {checkboxList} from "@/service/mdgl/MenDianXX";

    export default {
        components: {CityPicker,Province,ChineseDistricts},
        mixins: [XEditBase],
        beforeCreate() {
            let {required, phone, positiveEightTwo} = ruleBuilder;
            this.rules = {
                cangKuBH: [], // 仓库编号
                chuangJianRQ: [], // 创建日期
                suoShuMenDian: [], // 所属门店
                cangKuMC: [{required: true, message: '请输入仓库名称', trigger: 'blur'}], // 仓库名称
                cangKuMJ: [{required: true, message: '请输入仓库面积', trigger: 'blur'},positiveEightTwo()], // 仓库面积
                suoZaiDiQu: [{required: true, message: '请输入所在地区', trigger: 'blur'}], // 所在地区
                xiangXiDZ: [{required: true, message: '请输入详细地址', trigger: 'blur'}], // 详细地址
                fuZeRen: [{required: true, message: '请输入负责人', trigger: 'blur'}], // 负责人
                fuZeRenID: [], // 负责人ID
                fuZeRenShouJiHao: [{required: true, message: '请输入负责人手机号', trigger: 'blur'}, phone()], // 负责人手机号
                beiZhu: [], // 备注
                shiFuMoRenCangKu: [], // 是否默认仓库
                use: [], // 启用/禁用
            }
            this.titlePrefix = '仓库';
            this.defaultForm = {
                id: null,
                cangKuBH: "", // 仓库编号
                chuangJianRQ: "", // 创建日期
                suoShuMenDian: "", // 所属门店
                cangKuMC: "", // 仓库名称
                cangKuMJ: "", // 仓库面积
                suoZaiDiQu: "", // 所在地区
                xiangXiDZ: "", // 详细地址
                fuZeRen: "", // 负责人
                fuZeRenID: "", // 负责人ID
                fuZeRenShouJiHao: "", // 负责人手机号
                beiZhu: "", // 备注
                shiFuMoRenCangKu: "false", // 是否默认仓库
                use: "false", // 启用/禁用
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
            this.UseService = use;
        },
        data() {
            return {
                menDianOptions: [],
                Province,
                ChineseDistricts
            }
        },
        mounted() {
            this.getMenDianCheckboxList()
        },
        methods: {
            refreshMsg(){
                this.refresh()

            },
            getMenDianCheckboxList(){
                checkboxList().then((response)=>{
                    this.menDianOptions = response.data
                })
            },
        }
    }
</script>

<style scoped>

</style>