<template>
    <div class="x-page-container" ref="resize">
      <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd"  v-if="dataSize < 1">新增</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="40" fixed/>-->
            <el-table-column label="序号" type="index" />
            <el-table-column  prop="cangKuBH" label="仓库编号" />
            <el-table-column  prop="chuangJianRQ" label="创建日期" />
            <el-table-column  prop="suoShuMenDian" label="所属门店" />
            <el-table-column  prop="cangKuMC" label="仓库名称" />
            <el-table-column  prop="cangKuMJ" label="仓库面积㎡" width="100px"/>
            <!--            <el-table-column  prop="suoZaiDiQu" label="所在地区" />-->
            <el-table-column  prop="xiangXiDZ" label="详细地址" />
            <el-table-column  prop="fuZeRen" label="负责人" />
            <el-table-column  prop="fuZeRenShouJiHao" label="负责人手机号" />
            <el-table-column  prop="beiZhu" label="备注" />
            <el-table-column  label="是否默认仓库">
                <x-dict-show slot-scope="{row}" :code="row.shiFuMoRenCangKu" dictType="T_YES_OR_NO" />
            </el-table-column>
            <el-table-column width="180" label="启用状态">
                <x-dict-show slot-scope="{row}" :code="row.use" dictType="T_USE" />
            </el-table-column>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
                    <el-button type="primary" size="mini" round  @click="handleUpdate(row.id)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/kcgl/CangKu";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/kcgl/gjck/CangKuEdit";
    import Detail from "@/view/kcgl/gjck/CangKuDetail";
    import {checkboxList} from "@/service/mdgl/MenDianXX";
    export default {
        name: "CangKuList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                options: [],
                cangKuBH: [],
                dataSize:'',
                menDianOptions: [],
                search: {
                    cangKuBH: '',
                    cangKuMC: '',
                    use: '',
                }
            }
        },
        mounted() {
        },
        methods: {
            // 获取仓库下拉选择列表
/*            getCKList() {
                getCangKuList().then((response) => {
                    this.options = response.data
                    this.dataSize = response.data.size
                })
            },*/

            refresh() { //默认刷新列表方法
                this.table.loading = true;
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }

                    this.dataSize = response.data.total
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
        }
    }
</script>

<style scoped>
</style>
